import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./styles/index.scss";
import ElementUI from 'element-ui';
import 'font-awesome/css/font-awesome.css'
import 'element-ui/lib/theme-chalk/index.css';
import "@/utils/flexible";
import * as echarts from 'echarts'


Vue.use(ElementUI)

import axios from 'axios'
import { request } from './api.js';
Vue.prototype.$request = request;

// 全局引入bus，用来做无关组件之间的传值
const Bus = new Vue()
Vue.prototype.Bus = Bus

Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
// Vue.prototype.$httpUrl = "https://test.wapjin.com"
Vue.prototype.$httpUrl = "https://hslndx.whqtkj.cn"
// Vue.prototype.$httpUrl = "http://192.168.5.188:8000"
Vue.prototype.$CommunityTitle = "华师老年大学报名系统"


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
